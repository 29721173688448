//== COLOR
$color-a: #000000;
$color-a-light: #313131;
$color-b: hsl(33, 93%, 54%);	    // #f7941e;	// #2eca6a; // hsl(143, 63%, 49%);
$color-b-dark: hsl(33, 92%, 44%);	// #f7941e;	// #26a356; // hsl(143, 62%, 39%);
$color-b-trans: hsla(33, 92%, 44%, 0.05);
$color-c: #1B1B1B;
$color-d: #adadad;
$color-d-light: #d8d8d8;
$color-d-trans: #ffffff93;
$color-text-a: #555555;
$color-text-b: #2B2A2A;
$color-w: #ffffff;
$color-w-semi-trans: #ffffff40;
$color-trans: transparent;
$color-gray-light: #f3f3f3;
$opacity: .7;

$color-logo-a: hsl(33, 93%, 54%);
$color-logo-b: #0f385b;

$color-hover-b: #0f385b;

// $primary: #FFFF00;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$card-background-color: #fff;
// $card-color: #002134;

$migration-color-link: #43749c;
$migration-icon-color: #8e2525;

$card-color: $migration-color-link;
/*#region Migration zone *//*
$primary: #fed136;
$secondary: #ced4da;
$success: #28a745;
$info: #24aeff;
$warning: #ffa726;
$danger: #dc3545;
$light: #e9ecef;
$dark: #343a40;
/*#endregion Migration zone */
