@import "variables";
@import "mixins";

/*------/ Title /------*/
.title-wrap {
	padding-bottom: 4rem;
}

.title-a {
	font-size: 2.6rem;
	font-weight: 600;
}

.title-link {
	font-size: 1.2rem;
	font-weight: 300;
	padding-top: 1.2rem;

	a {
		color: $color-a-light;
	}

	span {
		font-size: 20px;
		padding-left: 4px;
		vertical-align: middle;
	}
}

.title-box-d {
	padding-bottom: 1.8rem;
	margin-bottom: 1rem;
	position: relative;

	.title-d {
		font-weight: 600;
		font-size: 2rem;

		&:after {
			content: '';
			position: absolute;
			width: 70px;
			height: 4px;
			background-color: $color-b;
			bottom: 20px;
			left: 0;
		}
	}
}
