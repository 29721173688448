//== RESPONSIVE
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$sm-max: $sm - 1;
$md-max: $md - 1;
$lg-max: $lg - 1;
$xl-max: $xl - 1;

@mixin breakpoint($mq) {
	@if $mq==$xs {
		@media (min-width: $xs) {
			@content;
		}
	}

	@else if $mq==$sm {
		@media (min-width: $sm) {
			@content;
		}
	}

	@else if $mq==$md {
		@media (min-width: $md) {
			@content;
		}
	}

	@else if $mq==$lg {
		@media (min-width: $lg) {
			@content;
		}
	}

	@else if $mq==$xl {
		@media (min-width: $xl) {
			@content;
		}
	}

	@else if $mq==$sm_max {
		@media (max-width: $sm-max) {
			@content;
		}
	}

	@else if $mq==md_max {
		@media (max-width: $md-max) {
			@content;
		}
	}

	@else if $mq==lg_max {
		@media (max-width: $lg-max) {
			@content;
		}
	}

	@else if $mq==xl_max {
		@media (max-width: $xl-max) {
			@content;
		}
	}
}
