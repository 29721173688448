@font-face {
  font-family: 'NAAR';
  src:  url('../fonts/NAAR/NAAR.eot?9j653');
  src:  url('../fonts/NAAR/NAAR.eot?9j653#iefix') format('embedded-opentype'),
    url('../fonts/NAAR/NAAR.ttf?9j653') format('truetype'),
    url('../fonts/NAAR/NAAR.woff?9j653') format('woff'),
    url('../fonts/NAAR/NAAR.svg?9j653#NAAR') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'NAAR' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-on:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-close-on:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-fb-on:before {
  content: "\e904";
}
.icon-fb:before {
  content: "\e905";
}
.icon-google-on:before {
  content: "\e906";
}
.icon-google:before {
  content: "\e907";
}
.icon-instagram-on:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-love-on:before {
  content: "\e90a";
}
.icon-love:before {
  content: "\e90b";
}
.icon-twitter-on:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}
.icon-youtube-on:before {
  content: "\e90e";
}
.icon-youtube:before {
  content: "\e90f";
}
.icon-pinterest-on:before {
  content: "\e910";
}
.icon-pinterest:before {
  content: "\e911";
}
.icon-share-on:before {
  content: "\e912";
}
.icon-share:before {
  content: "\e913";
}
.icon-trash-on:before {
  content: "\e914";
}
.icon-trash:before {
  content: "\e915";
}
.icon-arrowright:before {
  content: "\e916";
}
.icon-arrowright-on:before {
  content: "\e917";
}
.icon-activity:before {
  content: "\e918";
}
.icon-adventure:before {
  content: "\e919";
}
.icon-assistance:before {
  content: "\e91a";
}
.icon-bus:before {
  content: "\e91b";
}
.icon-calendar:before {
  content: "\e91c";
}
.icon-check:before {
  content: "\e91d";
}
.icon-city:before {
  content: "\e91e";
}
.icon-couple:before {
  content: "\e91f";
}
.icon-culture:before {
  content: "\e920";
}
.icon-dog:before {
  content: "\e921";
}
.icon-economic:before {
  content: "\e922";
}
.icon-family:before {
  content: "\e923";
}
.icon-flight:before {
  content: "\e924";
}
.icon-friend:before {
  content: "\e925";
}
.icon-honey:before {
  content: "\e926";
}
.icon-language:before {
  content: "\e927";
}
.icon-login:before {
  content: "\e928";
}
.icon-lonely:before {
  content: "\e929";
}
.icon-luxury:before {
  content: "\e92a";
}
.icon-menu:before {
  content: "\e92b";
}
.icon-nature:before {
  content: "\e92c";
}
.icon-noworries:before {
  content: "\e92d";
}
.icon-onlyarrow:before {
  content: "\e92e";
}
.icon-people:before {
  content: "\e92f";
}
.icon-pin:before {
  content: "\e930";
}
.icon-search:before {
  content: "\e931";
}
.icon-star-on:before {
  content: "\e932";
}
.icon-star:before {
  content: "\e933";
}
.icon-tour:before {
  content: "\e934";
}
.icon-wallet:before {
  content: "\e935";
}
