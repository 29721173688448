@import "variables";
@import "mixins";

$background-intro-top: #54a3dc;
.intro-top {
	height: 82px;
	background-color: $background-intro-top;
	margin: auto -15px;
}
/*------/ Intro Single /------*/
.intro-single {
	padding: 8.5rem 0 $section-padding-bottom;

	@include breakpoint(md_max) {
		padding-top: 9rem;
	}

	.title-single-box {
		padding: 1rem 0 1rem 2rem;
	}

	.title-single-box {
		border-left: 3px solid $color-b;

		.title-single {
			font-weight: 600;
			font-size: 2.2rem;

			@include breakpoint(md) {
				font-size: 2.1rem;
			}

			@include breakpoint(lg) {
				font-size: 2.5rem;
			}
		}
	}

	.breadcrumb-box {
		padding: 1rem 0 0 .5rem;
	}

	.breadcrumb {
		background-color: transparent;
		padding-right: 0;
		padding-left: 0;
	}
}

.intro-single-tiny {
	padding: 0;
	margin: 0 -15px 1rem -15px;
	background-color: rgb(235 235 235);
}
